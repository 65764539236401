import { GlobalStyles } from "./styles/GlobalStyles";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
//import { useState } from "react";

import Header from "./components/header/Header";
import SideLines from "./components/sideLines/SideLines";
import Intro from "./views/intro/Intro";
import About from "./views/about/About";
import Experience from "./views/experience/Experience";
import Collaboration from "./views/collaboration/Collaboration";
import Projects from "./views/projects/Projects";
import Contact from "./views/contact/Contact";
import Footer from "./components/footer/Footer";
import OtherProjects from "./views/otherProjects/OtherProjects";
import Loading from "./views/loading/Loading";
import Skills from "./views/skills/Skills";

function App() {
  //const [isLoading, setIsLoading] = useState(true);

  return (
    <main>
      <BrowserRouter>
        <GlobalStyles />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Header />
                <SideLines />
                <Intro />
                <Skills />
                <About />
                <Experience />
                <Collaboration />
                <Projects />
                <OtherProjects />
                <Contact />
                <Footer />
              </>
            }
          />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </BrowserRouter>
    </main>
  );
}

export default App;
