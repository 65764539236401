import React from "react";
import {
  Container,
  Content,
  HeadingContainer,
  Heading,
  Text,
  List,
  Item,
  PhotoContainer,
  Photo,
  Link,
} from "./About.Styled";

const About = () => {
  return (
    <Container id="About">
      <Content>
        <HeadingContainer>
          02. <Heading>About Me</Heading>
        </HeadingContainer>
        <Text>
          Hi! My name is Viet. I enjoy creating things that live on the
          internet. My interest in web development started back in 2021 when my
          friend recommended me a job position (Front-end developer).
        </Text>
        <Text>
          Fast-forward to today, and I've had the privilege of working at
          start-ups like{" "}
          <Link
            href="https://www.blockczech.io"
            target="_blank"
            rel="noopener noreferrer"
          >
            BlockCzech
          </Link>
          ,{" "}
          <Link
            href="https://www.oone.cz"
            target="_blank"
            rel="noopener noreferrer"
          >
            O/one
          </Link>
          ,{" "}
          <Link
            href="https://www.magiccraft.io"
            target="_blank"
            rel="noopener noreferrer"
          >
            MagicCraft
          </Link>{" "}
          , etc.
        </Text>
        <Text>
          My focus and interest are in web3 and innovative/revolutionary
          technologies.
        </Text>
        <Text>I describe myself as:</Text>
        <List>
          <Item>Friendly</Item>
          <Item>Reliable</Item>
          <Item>Creative</Item>
          <Item>Ambitious</Item>
          <Item>Cheerful</Item>
          <Item>Flexible</Item>
          <Item>Determined</Item>
          <Item>Team-oriented</Item>
          <Item>Communicative</Item>
        </List>
      </Content>
      <PhotoContainer>
        <Photo src="https://i.imgur.com/VzH0zYe.jpg" alt="Viet" />
      </PhotoContainer>
    </Container>
  );
};

export default About;
