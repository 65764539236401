import React, { useEffect, useState, useRef } from "react";
import { HashLink } from "react-router-hash-link";
import {
  Container,
  LogoContainer,
  Navigation,
  Links,
  Link,
  SidebarContainer,
  Sidebar,
} from "./Header.Styled";
import Button from "../button/Button";
import { FaLaptopCode } from "react-icons/fa";
import { useNavigate, useLocation } from "react-router-dom";
import { MediaQuery, useDeviceType } from "../../hooks/useDeviceType";
import { motion, useCycle } from "framer-motion";
import {
  navigationVariants,
  sidebarVariants,
  parentVariants,
  commonDownAppearVariants,
} from "../../animations/motion";
import { MenuToggle } from "./MenuToggle";

const Header = () => {
  const [showHeader, setShowHeader] = useState(true);
  const [isTop, setIsTop] = useState(true);
  const [isOpen, toggleOpen] = useCycle(false, true);
  const [delayOpen, toggleDelayOpen] = useCycle(false, true);

  const isDesktop = useDeviceType(MediaQuery.isDesktop);
  const navigate = useNavigate();
  const location = useLocation();
  const node = useRef();

  function toggleMenu() {
    if (!isOpen) {
      toggleDelayOpen();
    } else {
      setTimeout(() => toggleDelayOpen(), 540);
    }
    toggleOpen();
  }

  useEffect(() => {
    if (node) {
      let lastScrollY = 0;

      window.addEventListener("scroll", function () {
        let scrollTop = window.scrollY;

        if (scrollTop < lastScrollY) {
          setShowHeader(true);
        } else {
          setShowHeader(false);
        }
        if (window.scrollY === 0) {
          setIsTop(true);
        } else {
          setIsTop(false);
        }
        lastScrollY = scrollTop;
      });
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.documentElement.style.cssText =
        "overflow: hidden; height: 100%;";
      document.body.style.cssText = "overflow: hidden; height: 100%;";
    } else {
      document.documentElement.style.cssText = "overflow: auto; height: unset;";
      document.body.style.cssText = "overflow: auto; height: unset;";
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      toggleMenu();
      document.documentElement.style.cssText =
        "overflow: scroll; height: unset;";
      document.body.style.cssText = "overflow: auto; height: unset;";
    }
  }, [location]);

  return (
    <Container
      ref={node}
      isTop={isTop}
      showHeader={showHeader}
      as={motion.header}
      initial="closed"
      animate="open"
      variants={parentVariants}
    >
      <LogoContainer
        onClick={() => navigate("/")}
        as={motion.figure}
        variants={commonDownAppearVariants}
      >
        <FaLaptopCode />
        Viet
      </LogoContainer>
      {isDesktop ? (
        <Navigation
          as={motion.nav}
          initial="closed"
          animate="open"
          variants={parentVariants}
        >
          <Links>
            <Link as={motion.li} variants={commonDownAppearVariants}>
              01.
              <HashLink to="/#About">About</HashLink>
            </Link>
            <Link as={motion.li} variants={commonDownAppearVariants}>
              02.
              <HashLink to="/#Experience">Experience</HashLink>
            </Link>
            <Link as={motion.li} variants={commonDownAppearVariants}>
              03.
              <HashLink to="/#Work">Work</HashLink>
            </Link>
            <Link as={motion.li} variants={commonDownAppearVariants}>
              04.
              <HashLink to="/#Contact">Contact</HashLink>
            </Link>
          </Links>
          <Button
            href="https://resume.io/r/fAuE99Gl6"
            target="_blank"
            rel="noopener noreferrer"
            as={motion.a}
            variants={commonDownAppearVariants}
          >
            Resume
          </Button>
        </Navigation>
      ) : (
        <SidebarContainer
          as={motion.nav}
          initial={false}
          animate={isOpen ? "open" : "closed"}
        >
          <Sidebar
            as={motion.div}
            variants={sidebarVariants}
            isOpen={delayOpen}
          >
            {isOpen ? (
              <Navigation>
                <Links as={motion.ul} variants={navigationVariants}>
                  <Link>
                    01.
                    <HashLink to="/#About">About</HashLink>
                  </Link>
                  <Link>
                    02.
                    <HashLink to="/#Experience">Experience</HashLink>
                  </Link>
                  <Link>
                    03.
                    <HashLink to="/#Work">Work</HashLink>
                  </Link>
                  <Link>
                    04.
                    <HashLink to="/#Contact">Contact</HashLink>
                  </Link>
                </Links>
                <Button
                  href="https://resume.io/r/fAuE99Gl6"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Resume
                </Button>
              </Navigation>
            ) : null}
          </Sidebar>
          <MenuToggle toggle={toggleMenu} />
        </SidebarContainer>
      )}
    </Container>
  );
};

export default Header;
