import React from "react";
import {
  Container,
  Heading,
  Project,
  Subheading,
  Description,
  Stack,
  Skill,
  ProjectsContainer,
  Text,
} from "./OtherProjects.Styled";
import { FiFolder } from "react-icons/fi";
import { commonUpAppearVariants } from "../../animations/motion";
import { motion } from "framer-motion";

const Projects = [
  {
    name: "Saboteur Game",
    description:
      "I had to create a board game called The Saboteur for my term project. A player had options to play against the player or BOT.",
    stack: ["Java", "Greenfoot"],
  },
  {
    name: "Crypto Millionaires",
    description:
      "The web serves for listing cryptocurrencies, news and giveaways related to the crypto space.",
    stack: [
      "React",
      "REST APIs",
      "Chart.js",
      "Firebase",
      "Javascript",
      "Bootstrap",
    ],
  },
  {
    name: "Chatting App",
    description:
      "The app allowed the users to create a room or join existing rooms and chat. The users could see other people online in real-time. ",
    stack: ["Vue", "Javascript", "Firebase"],
  },
];

const OtherProject = ({ project }) => {
  return (
    <Project
      as={motion.article}
      variants={commonUpAppearVariants}
      initial="closed"
      whileInView="open"
      viewport={{ once: true }}
    >
      <Description>
        <FiFolder />
        <Subheading>{project.name}</Subheading>
        <Text>{project.description}</Text>
      </Description>
      <Stack>
        {project.stack.map((skill, j) => (
          <Skill key={j}>{skill}</Skill>
        ))}
      </Stack>
    </Project>
  );
};

const OtherProjects = () => {
  return (
    <Container>
      <Heading>Other Noteworthy Projects</Heading>
      <ProjectsContainer>
        {Projects.map((project, i) => (
          <OtherProject project={project} key={i} />
        ))}
      </ProjectsContainer>
    </Container>
  );
};

export default OtherProjects;
