import styled from "styled-components";
import { MediaQuery } from "../../hooks/useDeviceType";
import {
  GAINSBORO,
  LIGHT_EERIE_BLACK,
  NORMAL_HEIGHT,
  PRIMARY_FONT,
  SECONDARY_FONT,
  SECONDARY_SIZE,
  SLIM_WEIGHT,
  SMALL_SECONDARY_SIZE,
  SMALL_TITLE_SIZE,
} from "../../utils/variables";

export const Container = styled.section`
  max-width: 110rem;
  margin: 0 auto;
  padding: 10rem 12rem 10rem;

  @media ${MediaQuery.isMobile} {
    padding: 14rem 2rem 6rem;
  }
`;

export const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
  font-family: ${SECONDARY_FONT};
  margin-bottom: 4rem;

  &::after {
    content: "";
    width: 100%;
    height: 1px;
    display: block;
    background-color: ${GAINSBORO};
    opacity: 0.4;
    margin-left: 2rem;
  }
`;

export const Heading = styled.h1`
  margin-left: 1rem;
  font-family: ${PRIMARY_FONT};
  font-size: ${SMALL_TITLE_SIZE};
  white-space: nowrap;
`;

export const ProjectsContainer = styled.section`
  display: flex;
  flex-direction: column;
`;

export const ProjectDescription = styled.article`
  background-color: ${LIGHT_EERIE_BLACK};
  padding: 2rem;
  border-radius: 0.4rem;

  @media ${MediaQuery.isTablet} {
    background-color: transparent;
    padding: unset;
    margin: 4rem 0;
  }

  @media ${MediaQuery.isMobile} {
    background-color: transparent;
    padding: unset;
    margin: 4rem 0;
  }
`;

export const ProjectContentGroup = styled.div`
  display: flex;
  flex-direction: column;

  @media ${MediaQuery.isMobile} {
    width: 100%;
  }
`;

export const ProjectContent = styled.div`
  position: relative;
  width: 100%;

  ${ProjectContentGroup} {
    position: absolute;
    left: ${(props) => (!props.type ? "0" : "unset")};
    right: ${(props) => (!props.type ? "unset" : "0")};
    width: calc(100% + 20rem);
    align-items: ${(props) => (!props.type ? "flex-start" : "flex-end")};
    z-index: 8;
  }
`;

export const Text = styled.p`
  color: ${GAINSBORO};
  font-weight: ${SLIM_WEIGHT};
  font-size: ${SECONDARY_SIZE};
  line-height: ${NORMAL_HEIGHT};
`;

export const ProjectImage = styled.img`
  max-width: 54rem;
  max-height: 42rem;
  border-radius: 0.4rem;
  opacity: 0.6;
`;

export const ProjectName = styled.h1`
  font-family: ${PRIMARY_FONT};
  font-size: ${SMALL_TITLE_SIZE};
  margin-bottom: 2rem;
`;

export const ProjectSkill = styled.li`
  margin: 0 1rem;
  white-space: nowrap;
  font-family: ${SECONDARY_FONT};
  font-size: ${SMALL_SECONDARY_SIZE};

  @media ${MediaQuery.isTablet} {
    margin: 0 1rem 0 0;
  }

  @media ${MediaQuery.isMobile} {
    margin: 0.4rem 0.8rem;
  }
`;

export const ProjectStack = styled.ul`
  list-style-type: none;
  margin-top: 4rem;
  display: flex;

  @media ${MediaQuery.isMobile} {
    flex-wrap: wrap;
  }
`;

export const Project = styled.div`
  display: flex;
  margin: 8rem 0;

  @media ${MediaQuery.isTablet} {
    display: flex;
    align-items: center;
    border-radius: 0.6rem;
    margin: 4rem 0;
    padding: 6rem;
    width: 100%;
    height: fit-content;
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    background-image: ${(props) =>
      props.backgroundImage
        ? `linear-gradient(rgba(28, 28, 28, 0.9), rgba(35, 35, 35, 0.9)), url(${props.backgroundImage})`
        : "unset"};
  }

  @media ${MediaQuery.isMobile} {
    display: flex;
    align-items: center;
    border-radius: 0.6rem;
    margin: 4rem 0;
    padding: 2rem;
    width: 100%;
    height: fit-content;
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    background-image: ${(props) =>
      props.backgroundImage
        ? `linear-gradient(rgba(28, 28, 28, 0.9), rgba(35, 35, 35, 0.9)), url(${props.backgroundImage})`
        : "unset"};
  }
`;
