import React from "react";
import {
  Container,
  Heading,
  Logo,
  LogosContainer,
  Link,
  LogoContainer,
} from "./Collaboration.Styled";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import { MediaQuery, useDeviceType } from "../../hooks/useDeviceType";

// Import Swiper styles
import "swiper/css";
import "swiper/css/autoplay";

import ontee from "../../assets/ontee.png";
import oone from "../../assets/oone.png";
import blockczech from "../../assets/blockczech.png";
import goos from "../../assets/goos-finance.png";
import { PlateHero } from "../../assets/logos/Platehero";
import { Efekta } from "../../assets/logos/Efekta";
import { Liithos } from "../../assets/logos/Liithos";
import { Cryptomeda } from "../../assets/logos/Cryptomeda";
import { Tonana } from "../../assets/logos/Tonana";
import { TCGWorld } from "../../assets/logos/TCGWorld";

const Companies = [
  {
    link: "https://tcg.world",
    logo: <TCGWorld />,
  },
  {
    link: "https://cryptomeda.tech",
    logo: <Cryptomeda />,
  },
  {
    link: "https://tonana.org",
    logo: <Tonana />,
  },
  {
    image: {
      url: oone,
      alt: "O/one",
    },
    link: "https://www.oone.cz",
  },
  {
    link: "https://www.efekta.cz",
    logo: <Efekta />,
  },
  {
    image: {
      url: blockczech,
      alt: "BlockCzech",
    },
    link: "https://www.blockczech.io",
  },
  {
    image: {
      url: goos,
      alt: "Goos-finance",
    },
    link: "https://www.goosfinance.com",
  },
];

const Collaboration = () => {
  const isMobile = useDeviceType(MediaQuery.isMobile);
  const isTablet = useDeviceType(MediaQuery.isTablet);

  return (
    <Container>
      <Heading>
        I'm proud to have collaborated with some awesome companies:
      </Heading>
      <LogosContainer>
        <Swiper
          modules={[Autoplay]}
          spaceBetween={30}
          slidesPerView={isMobile ? 2 : isTablet ? 4 : 6}
          centeredSlides={true}
          className="swiper"
          autoplay={{ delay: 2000, disableOnInteraction: false }}
          loop
        >
          {Companies.map((company, i) => (
            <SwiperSlide>
              <Link
                href={company.link}
                target="_blank"
                rel="noreferrer noopener"
                key={i}
              >
                {company.logo ? (
                  <LogoContainer>{company.logo}</LogoContainer>
                ) : (
                  <Logo src={company.image.url} alt={company.image.alt} />
                )}
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </LogosContainer>
    </Container>
  );
};

export default Collaboration;
