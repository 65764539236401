import color from "color";

// colors
export const EERIE_BLACK = color("#1C1C1C");
export const LIGHT_EERIE_BLACK = color("#292929");
export const LIGHT_WHITE = color("#FFFFFF10");
export const GAINSBORO = color("#DADDD8");
export const LIGHT_GAINSBORO = color("#DADDD840");
export const ALABASTER = color("#ECEBE4");
export const CULTURED = color("#EEF0F2");
export const GHOST_WHITE = color("#FAFAFF");

// breakpoints
export const TABLET_BREAKPOINT = 564;
export const SMALL_MOBILE_BREAKPOINT = 375;
export const DESKTOP_BREAKPOINT = 1024;

// fonts
export const PRIMARY_FONT = "'Inter', sans-serif";
export const SECONDARY_FONT = "'Fira Code', monospace";

// font sizes
export const TITLE_SIZE = "6.4rem";
export const SMALL_TITLE_SIZE = "2.2rem";
export const PRIMARY_SIZE = "1.8rem";
export const SECONDARY_SIZE = "1.6rem";
export const SMALL_SECONDARY_SIZE = "1.4rem";

// font weights
export const SLIM_WEIGHT = "300";
export const NORMAL_WEIGHT = "400";
export const BOLDER_WEIGHT = "700";
export const BOLD_WEIGHT = "bold";

// line heights
export const NORMAL_HEIGHT = "2.6rem";
