import React from "react";
import {
  Container,
  Heading,
  HeadingContainer,
  List,
  SkillContainer,
  SkillImage,
  SkillName,
} from "./Skills.Styled";

import reactLogo from "../../assets/react-logo.png";
import vueLogo from "../../assets/vue-logo.png";
import firebaseLogo from "../../assets/firebase-logo.png";
import javascriptLogo from "../../assets/javascript-logo.png";
import typescriptLogo from "../../assets/typescript-logo.png";

const skills = [
  {
    name: "React",
    image: {
      url: reactLogo,
      alt: "React-logo",
    },
  },
  {
    name: "React Native",
    image: {
      url: reactLogo,
      alt: "React-logo",
    },
  },
  {
    name: "Vue",
    image: {
      url: vueLogo,
      alt: "Vue-logo",
    },
  },
  {
    name: "Javascript",
    image: {
      url: javascriptLogo,
      alt: "Javascript-logo",
    },
  },
  {
    name: "Typescript",
    image: {
      url: typescriptLogo,
      alt: "Typescript-logo",
    },
  },

  {
    name: "Firebase",
    image: {
      url: firebaseLogo,
      alt: "Firebase-logo",
    },
  },
];

const Skills = () => {
  return (
    <Container>
      <HeadingContainer>
        01. <Heading>Tools of trade</Heading>
      </HeadingContainer>
      <List>
        {skills.map((skill, i) => (
          <SkillContainer>
            <SkillImage src={skill.image.url} alt={skill.image.alt} />
            <SkillName>{skill.name}</SkillName>
          </SkillContainer>
        ))}
      </List>
    </Container>
  );
};

export default Skills;
