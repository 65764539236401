import React from "react";
import { LeftLine, RightLine, EmailLink, Link } from "./SideLines.Styled";
import { FiGithub, FiInstagram, FiLinkedin, FiTwitter } from "react-icons/fi";
import { motion } from "framer-motion";
import { commonOpacityAppearVariants } from "../../animations/motion";

const SideLines = () => {
  return (
    <motion.div
      variants={commonOpacityAppearVariants}
      initial="closed"
      animate="open"
      transition={{ delay: 2.2, duration: 1 }}
    >
      <LeftLine>
        <Link
          href="https://github.com/VietHoang37"
          rel="noopener noreferrer"
          target="_blank"
        >
          <FiGithub />
        </Link>
        <Link
          href="https://instagram.com/viet_hoang_ng"
          rel="noopener noreferrer"
          target="_blank"
        >
          <FiInstagram />
        </Link>
        <Link
          href="https://twitter.com/Nogon31"
          rel="noopener noreferrer"
          target="_blank"
        >
          <FiTwitter />
        </Link>
        <Link
          href="https://www.linkedin.com/in/viet-hoang-nguyen-591212226/"
          rel="noopener noreferrer"
          target="_blank"
        >
          <FiLinkedin />
        </Link>
      </LeftLine>
      <RightLine>
        <EmailLink href="mailto:viethoang.ng7@gmail.com">
          viethoang.ng7@gmail.com
        </EmailLink>
      </RightLine>
    </motion.div>
  );
};

export default SideLines;
