import styled from "styled-components";
import {
  CULTURED,
  EERIE_BLACK,
  GHOST_WHITE,
  SECONDARY_FONT,
  SMALL_SECONDARY_SIZE,
} from "../../utils/variables";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${EERIE_BLACK};
  z-index: 100;
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  -webkit-transition: width 1s ease-in-out;
  -moz-transition: width 1s ease-in-out;
  -o-transition: width 1s ease-in-out;
  transition: width 1s ease-in-out;
  position: absolute;
  bottom: 0;
`;
export const LoadingBar = styled.div`
  max-width: inherit;
  width: 100%;
  height: 10px;
  border: 1px solid ${CULTURED};
  display: flex;
  align-items: center;
`;

export const Filled = styled.div`
  background-color: ${CULTURED};
  width: ${(props) => props.percentage && `${props.percentage}%`};
  height: 100%;
  -webkit-transition: width 1s ease-in-out;
  -moz-transition: width 1s ease-in-out;
  -o-transition: width 1s ease-in-out;
  transition: width 1s ease-in-out;
`;

export const Heading = styled.h1`
  color: ${EERIE_BLACK};
  font-size: 2.8rem;
`;

export const HeadingContainer = styled.div`
  position: absolute;
  top: 4px;
  left: 4px;
  width: 99%;
  height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Button = styled.button`
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  border-color: ${GHOST_WHITE};
  padding: 1rem 2rem;
  color: ${GHOST_WHITE};
  cursor: pointer;
  background: linear-gradient(to left, transparent 50%, ${GHOST_WHITE} 50%)
    right;
  font-family: ${SECONDARY_FONT};
  font-size: ${SMALL_SECONDARY_SIZE};
  background-position: right;
  background-size: 200% 100%;
  transition: 0.5s ease;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: none;

  &:hover {
    color: ${EERIE_BLACK};
    background-position: left;
  }
`;

export const ImageContainer = styled.figure`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const Character = styled.img`
  max-height: 30rem;
  max-width: 30rem;
  transform: scaleX(-1);
  margin-bottom: 4rem;
`;

export const SpeechBubble = styled.img`
  height: 100%;
  width: 100%;
  transform: scale(1);
`;

export const SpeechBubbleContainer = styled.figure`
  position: absolute;
  right: -40px;
  top: -100px;
  height: 12rem;
  width: 20rem;
`;
