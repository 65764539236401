import styled from "styled-components";
import { MediaQuery } from "../../hooks/useDeviceType";
import {
  GAINSBORO,
  LIGHT_EERIE_BLACK,
  NORMAL_HEIGHT,
  PRIMARY_FONT,
  PRIMARY_SIZE,
  SECONDARY_FONT,
  SECONDARY_SIZE,
  SLIM_WEIGHT,
  SMALL_TITLE_SIZE,
} from "../../utils/variables";

export const Container = styled.section`
  max-width: 110rem;
  margin: 0 auto;
  padding: 2rem 6rem 10rem;

  svg {
    height: 4rem;
    width: 4rem;
  }

  @media ${MediaQuery.isMobile} {
    padding: 2rem 2rem 10rem;
  }
`;

export const Heading = styled.h1`
  margin-left: 1rem;
  font-family: ${PRIMARY_FONT};
  font-size: ${SMALL_TITLE_SIZE};
  white-space: nowrap;
  text-align: center;
  margin-bottom: 4rem;
`;

export const Description = styled.article``;

export const Subheading = styled.h1`
  font-family: ${PRIMARY_FONT};
  font-size: ${PRIMARY_SIZE};
  margin-top: 1.4rem;
  margin-bottom: 1rem;
`;

export const Skill = styled.li`
  white-space: nowrap;
  font-family: ${SECONDARY_FONT};
  font-size: 1.2rem;
  opacity: 0.4;
  margin: 1rem 1rem 0 0;

  @media ${MediaQuery.isMobile} {
    margin: 0.4rem 0.8rem;
  }
`;

export const Stack = styled.ul`
  list-style-type: none;
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
`;

export const ProjectsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

export const Project = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem;
  min-height: 30rem;
  max-width: 30rem;
  margin: 1rem;
  background-color: ${LIGHT_EERIE_BLACK};
`;

export const Text = styled.p`
  color: ${GAINSBORO};
  font-weight: ${SLIM_WEIGHT};
  font-size: ${SECONDARY_SIZE};
  line-height: ${NORMAL_HEIGHT};
`;
