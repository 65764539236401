export const Efekta = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100"
    height="50"
    viewBox="0 0 240 50"
    className={className}
  >
    <g
      id="Group_786"
      data-name="Group 786"
      transform="translate(-13.068 -23.848)"
    >
      <g
        id="Group_783"
        data-name="Group 783"
        transform="translate(61.773 63.378)"
      >
        <path
          id="Path_587"
          data-name="Path 587"
          d="M77.4,82.329a3.337,3.337,0,0,1,0-2.44,2.965,2.965,0,0,1,.628-.983,2.9,2.9,0,0,1,.956-.648,3.082,3.082,0,0,1,2.715.166,2.905,2.905,0,0,1,1.084,1.113,3.337,3.337,0,0,1,0,3.144A2.905,2.905,0,0,1,81.7,83.794a3.075,3.075,0,0,1-2.715.166,2.936,2.936,0,0,1-.956-.647A2.973,2.973,0,0,1,77.4,82.329Zm.624-1.22a2.28,2.28,0,0,0,.612,1.627,2.137,2.137,0,0,0,3.069,0,2.463,2.463,0,0,0,0-3.251,2.127,2.127,0,0,0-3.064,0A2.278,2.278,0,0,0,78.022,81.109Z"
          transform="translate(-77.167 -76.388)"
          fill="#f17909"
        />
        <path
          id="Path_588"
          data-name="Path 588"
          d="M90.155,84.141H87.837V78.129h2.046a1.789,1.789,0,0,1,1.237.423,1.491,1.491,0,0,1,.365.512,1.551,1.551,0,0,1,.13.628,1.439,1.439,0,0,1-.125.589,1.465,1.465,0,0,1-.345.485,1.78,1.78,0,0,1,.709.649,1.694,1.694,0,0,1,.277.932,1.779,1.779,0,0,1-.176.777,1.744,1.744,0,0,1-.482.614A2,2,0,0,1,90.155,84.141ZM88.7,80.494h1.031a1.7,1.7,0,0,0,.524-.063.682.682,0,0,0,.3-.189.756.756,0,0,0,0-1.047.674.674,0,0,0-.295-.188,1.79,1.79,0,0,0-.527-.061H88.7Zm0,2.83H90a1.944,1.944,0,0,0,.612-.077.958.958,0,0,0,.377-.228.985.985,0,0,0,.286-.705.965.965,0,0,0-.667-.929A1.923,1.923,0,0,0,90,81.307H88.7Z"
          transform="translate(-79.729 -76.414)"
          fill="#f17909"
        />
        <path
          id="Path_589"
          data-name="Path 589"
          d="M95.691,81.109a3.16,3.16,0,0,1,.394-1.564,2.911,2.911,0,0,1,1.093-1.117,3.011,3.011,0,0,1,1.552-.409,3.089,3.089,0,0,1,1.223.247,2.763,2.763,0,0,1,.975.679l-.574.566a2.058,2.058,0,0,0-.713-.489,2.257,2.257,0,0,0-.91-.191,2.071,2.071,0,0,0-1.552.654,2.246,2.246,0,0,0-.632,1.622,2.348,2.348,0,0,0,.3,1.188,2.064,2.064,0,0,0,.811.8,2.339,2.339,0,0,0,1.151.283,2,2,0,0,0,.992-.245,2.549,2.549,0,0,0,.736-.606l.608.549a2.866,2.866,0,0,1-1.023.808,2.959,2.959,0,0,1-1.312.307,3.274,3.274,0,0,1-1.242-.236,2.976,2.976,0,0,1-.989-.641,2.927,2.927,0,0,1-.648-.981A3.2,3.2,0,0,1,95.691,81.109Z"
          transform="translate(-81.616 -76.388)"
          fill="#f17909"
        />
        <path
          id="Path_590"
          data-name="Path 590"
          d="M109.672,84.141V81.307h-3.349v2.834h-.859V78.129h.859v2.365h3.349V78.129h.859v6.012Z"
          transform="translate(-83.962 -76.414)"
          fill="#f17909"
        />
        <path
          id="Path_591"
          data-name="Path 591"
          d="M115.126,82.329a3.347,3.347,0,0,1,0-2.44,2.978,2.978,0,0,1,.628-.983,2.911,2.911,0,0,1,.956-.648,3.082,3.082,0,0,1,2.715.166,2.9,2.9,0,0,1,1.084,1.113,3.331,3.331,0,0,1,0,3.144,2.9,2.9,0,0,1-1.084,1.113,2.967,2.967,0,0,1-4.3-1.465Zm.625-1.22a2.28,2.28,0,0,0,.612,1.627,2.137,2.137,0,0,0,3.069,0,2.46,2.46,0,0,0,0-3.251,2.127,2.127,0,0,0-3.064,0A2.277,2.277,0,0,0,115.751,81.109Z"
          transform="translate(-86.228 -76.388)"
          fill="#f17909"
        />
        <path
          id="Path_592"
          data-name="Path 592"
          d="M127.368,84.141h-1.8V78.129h1.8a3.405,3.405,0,0,1,2.062.57,2.947,2.947,0,0,1,.883,1.059,3.029,3.029,0,0,1,.32,1.377,3.064,3.064,0,0,1-.318,1.384,2.9,2.9,0,0,1-.876,1.056A3.424,3.424,0,0,1,127.368,84.141Zm2.213-2.064a2.319,2.319,0,0,0,.193-.941,2.29,2.29,0,0,0-.193-.937,2.137,2.137,0,0,0-.536-.745,1.927,1.927,0,0,0-.7-.381,3.515,3.515,0,0,0-1.019-.126h-.9v4.377h.9a3.577,3.577,0,0,0,1.019-.124,1.879,1.879,0,0,0,.7-.379A2.086,2.086,0,0,0,129.582,82.076Z"
          transform="translate(-88.79 -76.414)"
          fill="#f17909"
        />
        <path
          id="Path_593"
          data-name="Path 593"
          d="M139.464,84.141l-3.605-4.415v4.415H135V78.129h.6l3.605,4.415V78.129h.859v6.012Z"
          transform="translate(-91.056 -76.414)"
          fill="#f17909"
        />
        <path
          id="Path_594"
          data-name="Path 594"
          d="M145.051,83.6V77.587h.859V83.6Zm.645-6.44h-.558l.558-1.287h.9Z"
          transform="translate(-93.47 -75.872)"
          fill="#f17909"
        />
        <path
          id="Path_595"
          data-name="Path 595"
          d="M149.566,84.141V78.129h.859v2.536l2.473-2.536h1.132l-2.713,2.792,2.884,3.219h-1.1l-2.679-3.006v3.006Z"
          transform="translate(-94.554 -76.414)"
          fill="#f17909"
        />
        <path
          id="Path_596"
          data-name="Path 596"
          d="M161.427,83.2l.595-.582a1.872,1.872,0,0,0,1.467.771,1.125,1.125,0,0,0,.74-.254.841.841,0,0,0,.174-1.107,1.1,1.1,0,0,0-.371-.335,4.45,4.45,0,0,0-.486-.241c-.166-.07-.355-.15-.564-.242a3.252,3.252,0,0,1-.516-.27,1.53,1.53,0,0,1-.763-1.321,1.46,1.46,0,0,1,.522-1.131,1.879,1.879,0,0,1,1.3-.465,2.474,2.474,0,0,1,1.614.6l-.508.628a1.627,1.627,0,0,0-1.073-.415,1.144,1.144,0,0,0-.7.218.7.7,0,0,0-.3.591.616.616,0,0,0,.134.39,1.173,1.173,0,0,0,.371.3,4.938,4.938,0,0,0,.486.235q.248.1.555.244a3.314,3.314,0,0,1,.5.272,1.576,1.576,0,0,1,.783,1.375,1.616,1.616,0,0,1-.532,1.26,1.92,1.92,0,0,1-1.334.48,2.673,2.673,0,0,1-2.1-1Z"
          transform="translate(-97.402 -76.388)"
          fill="#f17909"
        />
        <path
          id="Path_597"
          data-name="Path 597"
          d="M173.255,81.109a3.168,3.168,0,0,1,.394-1.564,2.917,2.917,0,0,1,1.093-1.117,3.013,3.013,0,0,1,1.553-.409,3.086,3.086,0,0,1,1.222.247,2.767,2.767,0,0,1,.976.679l-.574.566a2.061,2.061,0,0,0-.714-.489,2.256,2.256,0,0,0-.91-.191,2.072,2.072,0,0,0-1.553.654,2.245,2.245,0,0,0-.631,1.622,2.348,2.348,0,0,0,.3,1.188,2.059,2.059,0,0,0,.811.8,2.337,2.337,0,0,0,1.151.283,2,2,0,0,0,.991-.245,2.563,2.563,0,0,0,.736-.606l.608.549a2.868,2.868,0,0,1-1.024.808,2.958,2.958,0,0,1-1.311.307,3.271,3.271,0,0,1-1.242-.236,2.979,2.979,0,0,1-.989-.641,2.939,2.939,0,0,1-.648-.981A3.2,3.2,0,0,1,173.255,81.109Z"
          transform="translate(-100.243 -76.388)"
          fill="#f17909"
        />
        <path
          id="Path_598"
          data-name="Path 598"
          d="M183.883,80.494h1.975v.813h-1.975v2.017h2.662v.818h-3.522V78.129h3.438v.817h-2.579Z"
          transform="translate(-102.589 -76.414)"
          fill="#f17909"
        />
        <path
          id="Path_599"
          data-name="Path 599"
          d="M194.943,84.141l-3.606-4.415v4.415h-.859V78.129h.6l3.605,4.415V78.129h.859v6.012Z"
          transform="translate(-104.379 -76.414)"
          fill="#f17909"
        />
        <path
          id="Path_600"
          data-name="Path 600"
          d="M204.993,84.141l-3.605-4.415v4.415h-.859V78.129h.6l3.605,4.415V78.129h.859v6.012Z"
          transform="translate(-106.793 -76.414)"
          fill="#f17909"
        />
        <path
          id="Path_601"
          data-name="Path 601"
          d="M214.656,77.587,212.6,80.853V83.6h-.859V80.853l-2.063-3.266h.963L212.165,80l1.53-2.415Zm-2.231-.428h-.557l.557-1.287h.9Z"
          transform="translate(-108.989 -75.872)"
          fill="#f17909"
        />
        <path
          id="Path_602"
          data-name="Path 602"
          d="M223.692,80.1l-1.761,2.758h-.6L219.57,80.1v4.045h-.859V78.129h.6l2.319,3.668,2.318-3.668h.6v6.012h-.859Z"
          transform="translate(-111.159 -76.414)"
          fill="#f17909"
        />
        <path
          id="Path_603"
          data-name="Path 603"
          d="M229.784,84.141V78.129h.859v6.012Z"
          transform="translate(-113.819 -76.414)"
          fill="#f17909"
        />
        <path
          id="Path_604"
          data-name="Path 604"
          d="M240.98,81.478h-1.417v2.662H238.7V78.129h2.276a1.886,1.886,0,0,1,1.254.4,1.729,1.729,0,0,1,.434.571,1.675,1.675,0,0,1,0,1.417,1.734,1.734,0,0,1-.434.57A1.886,1.886,0,0,1,240.98,81.478Zm-1.417-.813h1.375a1.045,1.045,0,0,0,.788-.268.846.846,0,0,0,0-1.187,1.047,1.047,0,0,0-.788-.264h-1.375Z"
          transform="translate(-115.961 -76.414)"
          fill="#f17909"
        />
        <path
          id="Path_605"
          data-name="Path 605"
          d="M249.6,84.141l-.633-1.547h-2.533l-.628,1.547h-.894l2.491-6.012h.6l2.487,6.012Zm-1.9-4.6-.926,2.235h1.853Z"
          transform="translate(-117.452 -76.414)"
          fill="#f17909"
        />
        <path
          id="Path_606"
          data-name="Path 606"
          d="M256.962,81.478h-1.418v2.662h-.859V78.129h2.277a1.886,1.886,0,0,1,1.253.4,1.719,1.719,0,0,1,.434.571,1.675,1.675,0,0,1,0,1.417,1.723,1.723,0,0,1-.434.57A1.885,1.885,0,0,1,256.962,81.478Zm-1.418-.813h1.375a1.043,1.043,0,0,0,.788-.268.846.846,0,0,0,0-1.187,1.046,1.046,0,0,0-.788-.264h-1.375Z"
          transform="translate(-119.799 -76.414)"
          fill="#f17909"
        />
        <path
          id="Path_607"
          data-name="Path 607"
          d="M262.816,83.6V77.587h.859V83.6Zm.646-6.44H262.9l.558-1.287h.9Z"
          transform="translate(-121.751 -75.872)"
          fill="#f17909"
        />
        <path
          id="Path_608"
          data-name="Path 608"
          d="M268.879,81.307h-.687v2.834h-.859V78.129h2.1a1.818,1.818,0,0,1,1.169.352,1.62,1.62,0,0,1,.434.548,1.589,1.589,0,0,1,0,1.378,1.62,1.62,0,0,1-.434.548,1.477,1.477,0,0,1-.7.318l2.155,2.868h-1.039Zm-.687-.813h1.2a.985.985,0,0,0,.721-.226.789.789,0,0,0,0-1.1.988.988,0,0,0-.721-.223h-1.2Z"
          transform="translate(-122.836 -76.414)"
          fill="#f17909"
        />
        <path
          id="Path_609"
          data-name="Path 609"
          d="M279.54,78.129l-2.058,3.266v2.746h-.859V81.395l-2.062-3.266h.963l1.527,2.415,1.53-2.415Z"
          transform="translate(-124.572 -76.414)"
          fill="#f17909"
        />
      </g>
      <g
        id="Group_784"
        data-name="Group 784"
        transform="translate(13.068 23.863)"
      >
        <rect
          id="Rectangle_674"
          data-name="Rectangle 674"
          width="10.901"
          height="10.901"
          fill="#f17909"
        />
        <path
          id="Path_610"
          data-name="Path 610"
          d="M34.869,45.669v-21.8h10.9v32.7h-32.7v-10.9h21.8Z"
          transform="translate(-13.068 -23.868)"
          fill="#f17909"
        />
      </g>
      <path
        id="Path_611"
        data-name="Path 611"
        d="M113.123,56.6h6.916V43.649h11.849l.059-6.236H120.039V30.155h15.137V23.918H113.123Z"
        transform="translate(-24.029 -0.017)"
        fill="#141a35"
      />
      <path
        id="Path_612"
        data-name="Path 612"
        d="M155.572,43.308h11.882V37.072H155.572V30.085h15.137V23.848H148.656V56.531h22.053V50.295H155.572Z"
        transform="translate(-32.562 0)"
        fill="#141a35"
      />
      <path
        id="Path_613"
        data-name="Path 613"
        d="M211.9,23.848h-9.2l-11.565,12.2v-12.2h-6.916V56.531h6.916V41.454l13.476,15.077h8.924L197.452,38.839Z"
        transform="translate(-41.103 0)"
        fill="#141a35"
      />
      <path
        id="Path_614"
        data-name="Path 614"
        d="M247.988,23.848h-23.82v6.237h8.452V56.531h6.939V30.085h8.43Z"
        transform="translate(-50.697 0)"
        fill="#141a35"
      />
      <path
        id="Path_615"
        data-name="Path 615"
        d="M272.52,23.848h-4.852L253.65,56.531h7.273l3.01-7.057h12.322l3.033,7.057h7.25Zm1.044,19.391h-6.983l3.5-8.1Z"
        transform="translate(-57.777)"
        fill="#141a35"
      />
      <path
        id="Path_616"
        data-name="Path 616"
        d="M84.448,43.308H96.33V37.072H84.448V30.085H99.585V23.848H77.532V56.531H99.585V50.295H84.448Z"
        transform="translate(-15.481 0)"
        fill="#141a35"
      />
    </g>
  </svg>
);
