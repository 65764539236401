import styled from "styled-components";
import { GAINSBORO, GHOST_WHITE, SECONDARY_FONT } from "../../utils/variables";
import { MediaQuery } from "../../hooks/useDeviceType";

export const LeftLine = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  width: 40px;
  bottom: 0;
  left: 4rem;
  z-index: 10;

  @media ${MediaQuery.isTablet} {
    display: none;
  }

  @media ${MediaQuery.isMobile} {
    display: none;
  }

  &:after {
    content: "";
    display: block;
    width: 1px;
    height: 90px;
    background-color: ${GAINSBORO};
  }
`;

export const Link = styled.a`
  cursor: pointer;
  margin: 1.4rem 0;

  svg {
    color: ${GAINSBORO};
  }

  &:hover {
    svg {
      color: ${GHOST_WHITE};
      transform: translateY(-6px);
      -moz-transform: translateY(-6px);
      -webkit-transform: translateY(-6px);
      -o-transform: translateY(-6px);
      transition: transform 0.4s ease-in-out;
      -webkit-transition: transform 0.4s ease-in-out;
      -moz-transition: transform 0.4s ease-in-out;
      -ms-transition: transform 0.4s ease-in-out;
    }
  }
`;

export const RightLine = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  width: 40px;
  bottom: 0;
  right: 4rem;
  z-index: 10;

  @media ${MediaQuery.isTablet} {
    display: none;
  }

  @media ${MediaQuery.isMobile} {
    display: none;
  }

  &:after {
    content: "";
    display: block;
    width: 1px;
    height: 280px;
    background-color: ${GAINSBORO};
  }
`;

export const EmailLink = styled.a`
  margin: 20px auto;
  padding: 10px;
  font-family: ${SECONDARY_FONT};
  font-size: 1.3rem;
  letter-spacing: 0.1rem;
  -webkit-writing-mode: vertical-rl;
  writing-mode: vertical-rl;
  text-decoration: none;
  color: ${GAINSBORO};

  &:hover {
    color: ${GHOST_WHITE};
    transform: translateY(-6px);
    -moz-transform: translateY(-6px);
    -webkit-transform: translateY(-6px);
    -o-transform: translateY(-6px);
    transition: transform 0.4s ease-in-out;
    -webkit-transition: transform 0.4s ease-in-out;
    -moz-transition: transform 0.4s ease-in-out;
    -ms-transition: transform 0.4s ease-in-out;
  }
`;
