import styled from "styled-components";
import { MediaQuery } from "../../hooks/useDeviceType";
import { CULTURED } from "../../utils/variables";

export const Container = styled.section`
  max-width: 120rem;
  margin: 0 auto;
  padding: 5rem;
  border-radius: 2rem;

  @media ${MediaQuery.isMobile} {
    padding: 4rem 2rem;
  }
`;

export const Heading = styled.h1`
  margin-bottom: 4rem;
  color: ${CULTURED};
  font-size: 2.4rem;

  @media ${MediaQuery.isMobile} {
    font-size: 1.8rem;
  }
`;

export const LogosContainer = styled.div`
  position: relative;
  height: 100px;
  display: flex;
  align-items: center;

  .swiper {
    height: 100%;

    .swiper-slide {
      opacity: 0.1;
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;
    }

    .swiper-slide-prev {
      opacity: 0.3;
    }

    .swiper-slide-next {
      opacity: 0.3;
    }

    .swiper-slide-active {
      opacity: 1;
    }
  }
`;

export const Logos = styled.figure`
  display: flex;
  overflow-x: auto;
  gap: 4rem;
`;

export const Logo = styled.img`
  max-height: 40px;
  height: 100%;
  width: 100%;
  filter: brightness(0) invert(1);
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;

  @media ${MediaQuery.isMobile} {
    width: 20rem;
    height: 6rem;
  }
`;

export const LogoContainer = styled.figure`
  height: 100%;
  width: 100%;
  filter: brightness(0) invert(1);
  position: relative;

  svg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  @media ${MediaQuery.isMobile} {
    width: 20rem;
    height: 8rem;
  }
`;

export const Link = styled.a`
  text-decoration: none;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  @media ${MediaQuery.isMobile} {
    display: flex;
    justify-content: center;
  }
`;
