import styled from "styled-components";
import { MediaQuery } from "../../hooks/useDeviceType";
import {
  NORMAL_HEIGHT,
  SECONDARY_SIZE,
  SLIM_WEIGHT,
  SMALL_SECONDARY_SIZE,
} from "../../utils/variables";

export const Container = styled.footer`
  padding: 4rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Text = styled.p`
  font-weight: ${SLIM_WEIGHT};
  font-size: ${SECONDARY_SIZE};
  line-height: ${NORMAL_HEIGHT};
  text-align: center;

  @media ${MediaQuery.isMobile} {
    font-size: ${SMALL_SECONDARY_SIZE};
  }
`;
