import styled from "styled-components";
import { MediaQuery } from "../../hooks/useDeviceType";
import {
  CULTURED,
  EERIE_BLACK,
  GAINSBORO,
  PRIMARY_SIZE,
  SECONDARY_FONT,
} from "../../utils/variables";

export const Container = styled.header`
  display: flex;
  justify-content: space-between;
  padding: 3rem 4rem;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 30;
  background-color: ${EERIE_BLACK};
  transform: ${(props) =>
    props.showHeader ? "translateY(0)" : "translateY(-100%)"};
  transition: all 0.3s ease-in-out;

  -webkit-box-shadow: ${(props) =>
    !props.isTop ? "0px 10px 14px 0px rgba(0, 0, 0, 0.1)" : "unset"};
  -moz-box-shadow: ${(props) =>
    !props.isTop ? "0px 10px 14px 0px rgba(0, 0, 0, 0.1)" : "unset"};
  box-shadow: ${(props) =>
    !props.isTop ? "0px 10px 14px 0px rgba(0, 0, 0, 0.1)" : "unset"};

  @media ${MediaQuery.isTablet} {
    padding: 2.8rem 4rem;
  }
`;

export const LogoContainer = styled.figure`
  display: flex;
  align-items: center;
  font-family: ${SECONDARY_FONT};
  font-size: 2.6rem;
  cursor: pointer;
`;

export const Navigation = styled.nav`
  display: flex;
  align-items: center;

  @media ${MediaQuery.isTablet} {
    flex-direction: column;
    padding: 8rem 4rem;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);

    a {
      color: ${EERIE_BLACK};
      border-color: ${EERIE_BLACK};
      background-position: right;
      margin-top: 4rem;
    }
  }

  @media ${MediaQuery.isMobile} {
    flex-direction: column;
    padding: 8rem 3rem;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);

    a {
      color: ${EERIE_BLACK};
      border-color: ${EERIE_BLACK};
      background-position: right;
      margin-top: 4rem;
    }
  }
`;

export const Links = styled.ul`
  display: flex;
  list-style-type: none;
  align-items: center;

  @media ${MediaQuery.isTablet} {
    flex-direction: column;
    align-items: flex-start;
  }

  @media ${MediaQuery.isMobile} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Link = styled.li`
  font-family: ${SECONDARY_FONT};
  color: ${CULTURED};
  font-size: 1.3rem;
  margin: 1rem 2rem;

  @media ${MediaQuery.isTablet} {
    color: ${EERIE_BLACK};
    font-size: ${PRIMARY_SIZE};
    margin: 2rem 0;
  }

  @media ${MediaQuery.isMobile} {
    color: ${EERIE_BLACK};
    font-size: ${PRIMARY_SIZE};
    margin: 2rem 0;
    font-size: 1.6rem;
  }

  a {
    color: ${GAINSBORO};
    text-decoration: none;
    margin-left: 0.8rem;

    &:hover {
      color: ${CULTURED};
    }

    @media ${MediaQuery.isTablet} {
      color: ${EERIE_BLACK};

      &:hover {
        color: unset;
      }
    }

    @media ${MediaQuery.isMobile} {
      color: ${EERIE_BLACK};

      &:hover {
        color: unset;
      }
    }
  }
`;

export const SidebarContainer = styled.nav`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 80px;
`;

export const Sidebar = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: ${(props) => (props.isOpen ? "300px" : "80px")};
  height: ${(props) => (props.isOpen ? "100vh" : "80px")};
  background: ${CULTURED};
  border-radius: 0.4rem;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
`;

export const MenuButton = styled.button`
  outline: none;
  border: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  position: absolute;
  top: 18px;
  left: 15px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: transparent;
`;
