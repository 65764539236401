import styled from "styled-components";
import { MediaQuery } from "../../hooks/useDeviceType";
import {
  GAINSBORO,
  NORMAL_HEIGHT,
  PRIMARY_FONT,
  SECONDARY_FONT,
  SECONDARY_SIZE,
  SLIM_WEIGHT,
  SMALL_SECONDARY_SIZE,
  TITLE_SIZE,
} from "../../utils/variables";

export const Container = styled.section`
  max-width: 110rem;
  margin: 0 auto;
  padding: 20rem 6rem 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${MediaQuery.isMobile} {
    padding: 20rem 3rem 10rem;
  }
`;

export const Heading = styled.h1`
  font-family: ${PRIMARY_FONT};
  font-size: ${TITLE_SIZE};
  margin-bottom: 2rem;
  text-align: center;
`;

export const Text = styled.p`
  font-weight: ${SLIM_WEIGHT};
  font-size: ${SECONDARY_SIZE};
  line-height: ${NORMAL_HEIGHT};
  max-width: 50rem;
  text-align: center;
  margin-bottom: 4rem;

  @media ${MediaQuery.isMobile} {
    max-width: unset;
  }
`;

export const SpecialText = styled.p`
  font-family: ${SECONDARY_FONT};
  font-size: ${SMALL_SECONDARY_SIZE};
  color: ${GAINSBORO};
  margin-bottom: 2rem;
`;
