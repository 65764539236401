import React, { useState } from "react";
import {
  Container,
  HeadingContainer,
  Heading,
  Content,
  Tabs,
  Tab,
  Description,
  Subheading,
  SpecialText,
  SpecialSubheading,
  Text,
  Tags,
  Tag,
  LocationText,
} from "./Experience.Styled";
import parse from "html-react-parser";

const Jobs = [
  {
    name: "Sensay",
    link: "https://sensay.io",
    position: "Front-end Developer",
    location: "London - remote",
    date: "April 2023 - Present",
    text: [
      "Creating new features and fixing bugs.",
      "Communicate with the backend team and designers.",
    ],
    stack: [
      "NextJS",
      "Typescript",
      "GitHub",
      "TailwindCSS",
      "Supabase",
      "Zustand",
      "ElevenLabs",
      "Vercel",
    ],
  },
  {
    name: "Digital Diamond",
    link: "",
    position: "React Native Developer (former) & Consultant (current)",
    location: "Prague - remote",
    date: "March 2023 - Present",
    text: [
      "Create an MVP.",
      "Communicate with the backend team and designers.",
    ],
    stack: [
      "React Native",
      "React.js",
      "JavaScript",
      "GitHub",
      "TailwindCSS",
      "GraphQL",
      "React Context API",
    ],
  },
  {
    name: "ChatGod",
    link: "https://chatgod.ai",
    position: "Front-end Developer",
    location: "Dubai - remote",
    date: "February 2023 - July 2023",
    text: [
      "Manage and write modern, performant, and maintainable code for our <a href='https://chatgod.ai' target='_blank' rel='noreferrer noopener'>subscription page</a>.",
      "Communicate with AI engineers and designers.",
    ],
    stack: [
      "React.js",
      "JavaScript",
      "Netlify",
      "GitHub",
      "Jira",
      "Styled-components",
      "REST APIs",
      "Ory Kratos",
      "React Context API",
    ],
  },
  {
    name: "MagicCraft",
    link: "https://magiccraft.io",
    position: "Front-end Developer",
    location: "Dubai - remote",
    date: "September 2022 - July 2023",
    text: [
      "Manage and write modern, performant, and maintainable code for our <a href='https://app.magiccraft.io' target='_blank' rel='noreferrer noopener'>NFT Marketplace</a> and Game Lobby, which is in-progress.",
      "Communicate with teams of engineers, designers, and clients on a daily basis.",
    ],
    stack: [
      "React.js",
      "JavaScript",
      "TypeScript",
      "Ethers.js",
      "Netlify",
      "Heroku",
      "GitHub",
      "Jira",
      "Styled-components",
      "Sass",
      "REST APIs",
      "Redux",
    ],
  },
  {
    name: "Goos Finance",
    link: "https://goosfinance.com",
    position: "CTO & Mobile App Developer",
    location: "Prague - remote",
    date: "July 2022 - January 2023",
    text: [
      "Create a web3 wallet, which will allow users to store, send and receive their tokens.",
      "Have a meeting with my team of engineers, designers and our potential clients on a daily basis.",
    ],
    stack: [
      "Technical Leadership",
      "Supervisory Skills",
      "React Native",
      "JavaScript",
      "TypeScript",
      "Node.js",
      "GitHub",
      "Trello",
      "REST APIs",
    ],
  },
  {
    name: "BlockCzech",
    link: "https://blockczech.io",
    position: "Front-end Developer",
    location: "Prague - remote",
    date: "January 2022 - July 2022",
    text: [
      "Refactor, fix bugs and write code for a diverse array of clients like <a href='https://tcg.world' target='_blank' rel='noreferrer noopener'>TCG World</a>, <a href='https://cryptomeda.tech' target='_blank' rel='noreferrer noopener'>Cryptomeda</a>, <a href='https://liithos.com' target='_blank' rel='noreferrer noopener'>Liithos</a>, <a href='https://tonana.org' target='_blank' rel='noreferrer noopener'>Tonana</a>, and internal projects <a href='https://hackatonx.tonana.org/' target='_blank' rel='noreferrer noopener'>(Tonana Hack-A-Ton)</a>.",
      "Communicate with product managers, designers, and engineers on daily basis.",
    ],
    stack: [
      "React.js",
      "Vue.js",
      "Next.js",
      "JavaScript",
      "TypeScript",
      "Ethers.js",
      "Git",
      "Jira",
      "Styled-components",
      "Sass",
      "REST APIs",
    ],
  },
  {
    name: "O/one",
    link: "https://oone.cz",
    position: "Front-end Developer",
    location: "Prague - remote",
    date: "June 2021 - July 2022",
    text: [
      "Write features and pages for clients like <a href='https://efekta.cz' target='_blank' rel='noreferrer noopener'>Efekta</a> and <a href='https://platehero.co' target='_blank' rel='noreferrer noopener'>Platehero</a>.",
      "Manage, set up and work with React-Admin.",
    ],
    stack: [
      "React.js",
      "Next.js",
      "Swiper.js",
      "React-Admin",
      "TypeScript",
      "Git",
      "Styled-components",
      "Tailwind",
      "REST APIs",
    ],
  },
];

const Experience = () => {
  const [jobIndex, setJobIndex] = useState(0);

  return (
    <Container id="Experience">
      <HeadingContainer>
        03. <Heading>Where I've Worked</Heading>
      </HeadingContainer>
      <Content>
        <Tabs>
          {Jobs.map((job, i) => (
            <Tab key={i} active={jobIndex === i} onClick={() => setJobIndex(i)}>
              {job.name}
            </Tab>
          ))}
        </Tabs>
        {Jobs.map((job, i) => (
          <Description key={i} active={jobIndex === i}>
            <Subheading>
              {job.position}{" "}
              <SpecialSubheading
                href={job.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                &nbsp;@&nbsp;{job.name}
              </SpecialSubheading>
            </Subheading>
            <LocationText>{job.location}</LocationText>
            <SpecialText>{job.date}</SpecialText>
            {job.text.map((paragraph, j) => (
              <Text key={j}>{parse(`<span>${paragraph}</span>`)}</Text>
            ))}
            <Tags>
              {job.stack.map((skill, j) => (
                <Tag key={j}>{skill}</Tag>
              ))}
            </Tags>
          </Description>
        ))}
      </Content>
    </Container>
  );
};

export default Experience;
