import React from "react";
import {
  Container,
  SpecialText,
  Title,
  Heading,
  Text,
  Link,
} from "./Intro.Styled";
import RubberBandCharacter from "../../components/rubberBandCharacter/RubberBandCharacter";
import { motion } from "framer-motion";

const Intro = () => {
  function createRubberBandWord(text) {
    let tempArr = [];

    for (let i = 0; i < text.length; i++) {
      tempArr.push(
        text[i] !== " " ? (
          <RubberBandCharacter key={i}>{text[i]}</RubberBandCharacter>
        ) : (
          <span> </span>
        )
      );
    }

    return tempArr;
  }

  return (
    <Container
      as={motion.section}
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 1.8, duration: 0.5 }}
    >
      <SpecialText>Hi, my name is</SpecialText>
      <Title>
        {createRubberBandWord("Viet Hoang Nguyen").map((letter) => letter)}
      </Title>
      <Heading>
        {createRubberBandWord("I build things for the web.").map(
          (letter) => letter
        )}
      </Heading>
      <Text>
        I'm a software engineer specializing in front-end development. I am
        deeply passionate about creating visually stunning and highly
        interactive digital experiences. 
        {/* Currently, I'm focused on building accessible features and
        websites related to the game at&nbsp;
        <Link
          href="https://www.magiccraft.io"
          target="_blank"
          rel="noopener noreferrer"
        >
          MagicCraft.
        </Link> */}
      </Text>
    </Container>
  );
};

export default Intro;
