import styled from "styled-components";
import { MediaQuery } from "../../hooks/useDeviceType";
import {
  BOLDER_WEIGHT,
  CULTURED,
  EERIE_BLACK,
  GAINSBORO,
  GHOST_WHITE,
  LIGHT_GAINSBORO,
  LIGHT_WHITE,
  NORMAL_HEIGHT,
  NORMAL_WEIGHT,
  PRIMARY_FONT,
  PRIMARY_SIZE,
  SECONDARY_FONT,
  SECONDARY_SIZE,
  SLIM_WEIGHT,
  SMALL_SECONDARY_SIZE,
  SMALL_TITLE_SIZE,
} from "../../utils/variables";

export const Container = styled.section`
  max-width: 80rem;
  margin: 0 auto;
  padding: 10rem 6rem;

  @media ${MediaQuery.isTablet} {
    max-width: unset;
  }

  @media ${MediaQuery.isMobile} {
    max-width: unset;
    padding: 10rem 3rem;
  }
`;

export const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
  font-family: ${SECONDARY_FONT};
  margin-bottom: 4rem;

  &::after {
    content: "";
    width: 100%;
    height: 1px;
    display: block;
    background-color: ${GAINSBORO};
    opacity: 0.4;
    margin-left: 2rem;
  }
`;

export const Heading = styled.h1`
  margin-left: 1rem;
  font-family: ${PRIMARY_FONT};
  font-size: ${SMALL_TITLE_SIZE};
  white-space: nowrap;
`;

export const Content = styled.div`
  display: flex;

  @media ${MediaQuery.isMobile} {
    flex-direction: column;
  }
`;

export const Tabs = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: column;

  @media ${MediaQuery.isMobile} {
    flex-direction: row;
    overflow-y: hidden;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    padding-bottom: 1rem;
    margin-bottom: 2rem;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const Tab = styled.li`
  cursor: pointer;
  display: inline-block;
  color: ${(props) => (props.active ? GHOST_WHITE : "unset")};
  font-size: ${SMALL_SECONDARY_SIZE};
  line-height: ${NORMAL_HEIGHT};
  padding: 1rem 2rem;
  white-space: nowrap;
  border-left: ${(props) =>
    props.active ? `2px solid ${GHOST_WHITE}` : `2px solid ${LIGHT_GAINSBORO}`};
  background-color: ${(props) => (props.active ? LIGHT_WHITE : "transparent")};

  @media ${MediaQuery.isMobile} {
    border-left: unset;
    border-bottom: ${(props) =>
      props.active
        ? `2px solid ${GHOST_WHITE}`
        : `2px solid ${LIGHT_GAINSBORO}`};
  }
`;

export const Description = styled.div`
  margin-left: 3rem;
  display: ${(props) => (props.active ? "block" : "none")};
  -webkit-animation: fadein 1.4s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1.4s; /* Firefox < 16 */
  -ms-animation: fadein 1.4s; /* Internet Explorer */
  -o-animation: fadein 1.4s; /* Opera < 12.1 */
  animation: fadein 1.4s;

  @media ${MediaQuery.isMobile} {
    margin-left: unset;
  }

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Firefox < 16 */
  @-moz-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Internet Explorer */
  @-ms-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  /* Opera < 12.1 */
  @-o-keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const Subheading = styled.h3`
  font-family: ${PRIMARY_FONT};
  font-size: ${PRIMARY_SIZE};
  font-weight: ${NORMAL_HEIGHT};
  color: ${GAINSBORO};
  line-height: 3rem;
  margin-bottom: 1rem;

  @media ${MediaQuery.isMobile} {
    font-size: ${SECONDARY_SIZE};
  }
`;

export const SpecialSubheading = styled.a`
  color: ${GHOST_WHITE};
  font-family: ${PRIMARY_FONT};
  font-size: ${PRIMARY_SIZE};
  font-weight: ${BOLDER_WEIGHT};
  text-decoration: none;
  white-space: nowrap;
`;

export const SpecialText = styled.p`
  font-family: ${SECONDARY_FONT};
  font-size: ${SMALL_SECONDARY_SIZE};
  margin-bottom: 2rem;
`;

export const Text = styled.p`
  color: ${GAINSBORO};
  font-weight: ${SLIM_WEIGHT};
  font-size: ${SECONDARY_SIZE};
  line-height: ${NORMAL_HEIGHT};
  margin-bottom: 2rem;
  display: flex;

  &::before {
    content: "▹";
    display: block;
    margin-right: 1rem;
  }

  a {
    text-decoration: none;
    font-weight: ${NORMAL_WEIGHT};
    color: ${GHOST_WHITE};
    display: inline-block;
    position: relative;
    white-space: nowrap;

    &:hover::after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 2px;
      bottom: 0;
      left: 0;
      background-color: ${GHOST_WHITE};
      transform-origin: bottom right;
      transition: transform 0.25s ease-out;
    }
  }
`;

export const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Tag = styled.div`
  white-space: nowrap;
  color: ${EERIE_BLACK};
  background-color: ${CULTURED};
  font-weight: ${NORMAL_WEIGHT};
  font-size: ${SMALL_SECONDARY_SIZE};
  padding: 0.6rem 1.2rem;
  border-radius: 0.6rem;
  margin: 0.2rem;
`;

export const LocationText = styled.p`
  font-family: ${SECONDARY_FONT};
  font-size: ${SMALL_SECONDARY_SIZE};
  margin-bottom: 2rem;
  background-color: ${GAINSBORO};
  color: ${EERIE_BLACK};
  width: fit-content;
  padding: 0.2rem 0.8rem;
  border-radius: 0.4rem;
`;
