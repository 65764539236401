import React, { useState } from "react";

const RubberBandCharacter = ({ children }) => {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
    setTimeout(() => setIsHovering(false), 800);
  };

  return (
    <span
      className={isHovering ? "bouncing" : ""}
      onMouseOver={handleMouseOver}
    >
      {children}
    </span>
  );
};

export default RubberBandCharacter;
