import * as React from "react";

export const Tonana = (props) => (
  <svg
    width="100"
    height="100"
    viewBox="0 0 500 100"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M149.795 55.01c2.856-24.669-14.827-46.983-39.497-49.839C85.628 2.315 63.314 20 60.458 44.67c-2.856 24.67 14.828 46.983 39.498 49.84 24.669 2.855 46.983-14.828 49.839-39.498Z"
      fill="#E98C02"
      fillOpacity={0.44}
    />
    <path
      d="M149.795 55.01c2.856-24.669-14.827-46.983-39.497-49.839C85.628 2.315 63.314 20 60.458 44.67c-2.856 24.67 14.828 46.983 39.498 49.84 24.669 2.855 46.983-14.828 49.839-39.498Z"
      fill="#FFC916"
      fillOpacity={0.17}
    />
    <path
      d="m190.797 78.19-1.668 2.551a4 4 0 0 1-4.051 1.748l-4.394-.785c-25.091-4.482-50.345-9.823-75.821-9.039l-.553.017c-21.79.688-43.296 4.804-63.415 13.201-1.413.59-2.737 1.151-3.95 1.671-5.065 2.173-9.996 4.802-13.772 8.819-3.78 4.021-7.104 8.696-9.264 11.971-1.569 2.38-4.57 3.542-7.071 2.174-1.682-.919-3.374-2.111-4.151-3.468-.637-1.111-.746-2.712-.68-4.108.09-1.905 1.314-3.502 2.911-4.543 2.24-1.461 5.477-3.705 7.583-5.753 1.007-.98 2.072-2.22 3.07-3.48 2.938-3.708 3.57-8.624 3.044-13.326-.373-3.342-.487-7.213.346-10.198 1.622-5.814 3.39-10.037 7.218-14.706C36.72 38.08 47.89 35.231 64.047 31.27c10.25-2.514 15.531-3.31 25.78-4.12 12.099-.959 19.229-1.065 31.319 0 14.632 1.287 23.321 1.83 36.976 7.24 2.346.93 5.035 2.225 7.588 3.546 6.68 3.454 12.775 8.013 17.479 13.88 2.97 3.703 6.001 8.14 7.421 12.237.76 2.191 1.241 3.433 1.559 5.73.155 1.126.17 2.556.14 3.768-.04 1.66-.604 3.252-1.512 4.64Z"
      stroke="#FFC916"
      strokeWidth={3}
    />
    <path
      d="M149.795 55.01c2.856-24.669-14.827-46.983-39.497-49.839C85.628 2.315 63.314 20 60.458 44.67c-2.856 24.67 14.828 46.983 39.498 49.84 24.669 2.855 46.983-14.828 49.839-39.498Z"
      fill="#E98C02"
      fillOpacity={0.44}
    />
    <path
      d="M149.795 55.01c2.856-24.669-14.827-46.983-39.497-49.839C85.628 2.315 63.314 20 60.458 44.67c-2.856 24.67 14.828 46.983 39.498 49.84 24.669 2.855 46.983-14.828 49.839-39.498Z"
      fill="#FFC916"
      fillOpacity={0.17}
    />
    <path
      d="M149.795 55.01c2.856-24.669-14.827-46.983-39.497-49.839C85.628 2.315 63.314 20 60.458 44.67c-2.856 24.67 14.828 46.983 39.498 49.84 24.669 2.855 46.983-14.828 49.839-39.498Z"
      fill="#E98C02"
      fillOpacity={0.44}
    />
    <path
      d="M149.795 55.01c2.856-24.669-14.827-46.983-39.497-49.839C85.628 2.315 63.314 20 60.458 44.67c-2.856 24.67 14.828 46.983 39.498 49.84 24.669 2.855 46.983-14.828 49.839-39.498Z"
      fill="#FFC916"
      fillOpacity={0.17}
    />
    <path
      d="m190.797 78.19-1.668 2.551a4 4 0 0 1-4.051 1.748l-4.394-.785c-25.091-4.482-50.345-9.823-75.821-9.039l-.553.017c-21.79.688-43.296 4.804-63.415 13.201-1.413.59-2.737 1.151-3.95 1.671-5.065 2.173-9.996 4.802-13.772 8.819-3.78 4.021-7.104 8.696-9.264 11.971-1.569 2.38-4.57 3.542-7.071 2.174-1.682-.919-3.374-2.111-4.151-3.468-.637-1.111-.746-2.712-.68-4.108.09-1.905 1.314-3.502 2.911-4.543 2.24-1.461 5.477-3.705 7.583-5.753 1.007-.98 2.072-2.22 3.07-3.48 2.938-3.708 3.57-8.624 3.044-13.326-.373-3.342-.487-7.213.346-10.198 1.622-5.814 3.39-10.037 7.218-14.706C36.72 38.08 47.89 35.231 64.047 31.27c10.25-2.514 15.531-3.31 25.78-4.12 12.099-.959 19.229-1.065 31.319 0 14.632 1.287 23.321 1.83 36.976 7.24 2.346.93 5.035 2.225 7.588 3.546 6.68 3.454 12.775 8.013 17.479 13.88 2.97 3.703 6.001 8.14 7.421 12.237.76 2.191 1.241 3.433 1.559 5.73.155 1.126.17 2.556.14 3.768-.04 1.66-.604 3.252-1.512 4.64Z"
      stroke="#FFC916"
      strokeWidth={3}
    />
    <path
      d="M236.384 73V40.688h-13.048V33.52h34.776v7.168h-13.048V73h-8.68Zm43.615.616c-3.882 0-7.298-.84-10.248-2.52-2.912-1.717-5.189-4.088-6.832-7.112-1.605-3.061-2.408-6.645-2.408-10.752 0-4.107.803-7.672 2.408-10.696 1.643-3.061 3.92-5.432 6.832-7.112 2.912-1.68 6.328-2.52 10.248-2.52 3.92 0 7.336.84 10.248 2.52 2.95 1.68 5.227 4.05 6.832 7.112 1.643 3.024 2.464 6.57 2.464 10.64 0 4.107-.821 7.69-2.464 10.752-1.605 3.061-3.882 5.45-6.832 7.168-2.912 1.68-6.328 2.52-10.248 2.52Zm0-7.392c3.286 0 5.843-1.139 7.672-3.416 1.867-2.315 2.8-5.507 2.8-9.576 0-4.107-.914-7.28-2.744-9.52-1.829-2.277-4.405-3.416-7.728-3.416-3.248 0-5.805 1.139-7.672 3.416-1.829 2.24-2.744 5.413-2.744 9.52 0 4.07.915 7.261 2.744 9.576 1.867 2.277 4.424 3.416 7.672 3.416ZM307.938 73V33.52h6.44l19.712 25.144V33.52h8.064V73h-6.384l-19.768-25.2V73h-8.064Zm39.308 0 18.144-39.48h7L390.534 73h-8.736l-3.696-8.568h-18.424L356.038 73h-8.792Zm21.56-30.24-6.216 14.896h12.656l-6.328-14.896h-.112ZM395.626 73V33.52h6.44l19.712 25.144V33.52h8.064V73h-6.384L403.69 47.8V73h-8.064Zm39.308 0 18.144-39.48h7L478.222 73h-8.736l-3.696-8.568h-18.424L443.726 73h-8.792Zm21.56-30.24-6.216 14.896h12.656l-6.328-14.896h-.112Z"
      fill="#fff"
    />
  </svg>
);

export default Tonana;
