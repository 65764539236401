import React from "react";
import {
  Container,
  HeadingContainer,
  Heading,
  ProjectsContainer,
  ProjectContent,
  ProjectDescription,
  ProjectImage,
  ProjectName,
  ProjectSkill,
  ProjectStack,
  ProjectContentGroup,
  Project,
  Text,
} from "./Projects.Styled";
import { MediaQuery, useDeviceType } from "../../hooks/useDeviceType";
import { motion } from "framer-motion";
import { commonUpAppearVariants } from "../../animations/motion";

import thanglong from "../../assets/thanglong.png";
import ayurveda from "../../assets/ayurveda-space.png";
import myresume from "../../assets/myresume.png";

const Websites = [
  {
    image: {
      url: thanglong,
      alt: "Thanglong-restaurant",
    },
    name: "Thanglong Restaurant",
    description:
      "The web is made for the asian restaurant that informs the customers about the daily menu, news and other things related to the restaurant.",
    stack: ["React", "Styled-components", "Firebase", "Javascript", "Heroku"],
  },
  {
    image: {
      url: myresume,
      alt: "The chat themed portfolio",
    },
    name: "Chat Themed Portfolio",
    description:
      "My previous personal portfolio was themed as text chat to add some uniqueness to it. The website had features like replying to the user's text by sending text with my contacts and some memes depending on the amount of received messages.",
    stack: ["React", "Styled-components", "Firebase", "Javascript", "Heroku"],
  },
  {
    image: {
      url: ayurveda,
      alt: "Ayurveda-space",
    },
    name: "Ayurveda Space",
    description:
      "The web is made for the expert/doctor that offers his services. It has Auth flow implemented with chat feature. There is one more feature which is being developed, it is e-shop.",
    stack: ["React", "Styled-components", "Firebase", "Javascript", "Heroku"],
  },
];

const AnimatedProject = ({ index, web }) => {
  const isDesktop = useDeviceType(MediaQuery.isDesktop);

  return (
    <>
      {isDesktop ? (
        <Project
          as={motion.div}
          variants={commonUpAppearVariants}
          transition={{ delay: 0.5, duration: 1 }}
          initial="closed"
          whileInView="open"
          viewport={{ once: true }}
        >
          {index % 2 === 0 ? (
            <ProjectImage src={web.image.url} alt={web.image.alt} />
          ) : null}
          <ProjectContent type={index % 2 === 0}>
            <ProjectContentGroup>
              <ProjectName>{web.name}</ProjectName>
              <ProjectDescription>
                <Text>{web.description}</Text>
                <ProjectStack>
                  {web.stack.map((skill, j) => (
                    <ProjectSkill key={j}>{skill}</ProjectSkill>
                  ))}
                </ProjectStack>
              </ProjectDescription>
            </ProjectContentGroup>
          </ProjectContent>
          {index % 2 !== 0 ? (
            <ProjectImage src={web.image.url} alt={web.image.alt} />
          ) : null}
        </Project>
      ) : (
        <Project
          key={index}
          backgroundImage={web.image.url}
          as={motion.div}
          variants={commonUpAppearVariants}
          transition={{ delay: 0.5, duration: 1 }}
          initial="closed"
          whileInView="open"
          viewport={{ once: true }}
        >
          <ProjectContentGroup>
            <ProjectName>{web.name}</ProjectName>
            <ProjectDescription>
              <Text>{web.description}</Text>
            </ProjectDescription>
            <ProjectStack>
              {web.stack.map((skill, j) => (
                <ProjectSkill key={j}>{skill}</ProjectSkill>
              ))}
            </ProjectStack>
          </ProjectContentGroup>
        </Project>
      )}
    </>
  );
};

const Projects = () => {
  return (
    <Container id="Work">
      <HeadingContainer>
        04. <Heading>Some Things I've Built</Heading>
      </HeadingContainer>
      <ProjectsContainer>
        {Websites.map((web, i) => (
          <AnimatedProject key={i} web={web} index={i} />
        ))}
      </ProjectsContainer>
    </Container>
  );
};

export default Projects;
