import React from "react";
import { Container } from "./Button.Styled";

const Button = ({ children, href, style, as, variants }) => {
  return (
    <Container
      href={href}
      style={style}
      rel="noopener noreferrer"
      target="_blank"
      as={as}
      variants={variants}
    >
      {children}
    </Container>
  );
};

export default Button;
