import * as React from "react";

export const Cryptomeda = (props) => (
  <svg
    width="100"
    height="80"
    viewBox="0 0 400 80"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)" fill="#492EF9">
      <path d="M33.606 65.808h-23.8c-5.551 0-8.357-2.079-8.357-7.701L.055 8.008C.055 2.378 2.86.246 8.412.246L33.6 5.716v19.647L17.494 23.06v28.77h16.105l.007 13.978ZM69.584 65.807h-12.55v-16.44h-7.138v16.44H37.328V5.715L61 9.322c6.97 1.166 8.584 6.359 8.584 12.166V37.51c0 3.668-1.706 5.437-4.512 6.294v.12c2.746.356 4.512 2.08 4.512 5.748v16.135ZM57.02 38.242V26.759l-7.138-.711v12.18l7.138.014ZM106.869 40.32c0 5.804-3.111 7.883-8.662 7.883H96.62v17.604H84.052V48.203h-1.83c-5.551 0-8.538-2.079-8.538-7.883V11.07l12.572 1.013V37.02h8.055v-24.24l12.565.712-.007 26.83ZM144.079 45.944c0 5.807-3.051 7.882-8.603 7.882h-11.954v11.981h-12.565V13.48l24.523 1.397c5.552 0 8.602 2.2 8.602 8.008l-.003 23.058Zm-12.569-3.242V28.155h-7.988v14.547h7.988ZM180.38 28.157h-10.433v37.652h-12.565V28.157h-10.433V14.879l33.431 2.093v11.185ZM216.981 58.108c0 5.44-2.806 7.701-8.358 7.701h-16.41c-5.246 0-8.358-2.132-8.358-7.701V24.735c0-5.196 2.806-7.762 8.358-7.762h16.428c5.321 0 8.357 2.2 8.357 7.762l-.017 33.373Zm-12.569-3.483V28.218h-7.992v26.407h7.992ZM269.137 65.807h-12.384V42.012l-10.429 19.64h-1.649l-10.369-17.667v21.822h-12.384V16.97l9.819-.71 13.792 25.65 13.604-27.723 10.004-.71-.004 52.33ZM302.383 65.81h-28.305V12.086l28.305-2.793v11.881l-15.74 2.097v12.343h15.74V46.74h-15.74v7.886h15.74V65.81ZM339.893 58.105c0 5.257-2.99 7.702-8.417 7.702h-24.761V7.925l24.768-2.093c5.186 0 8.418 2.132 8.418 7.762l-.008 44.511Zm-12.561-3.482V19.87l-8.052.71v34.041h8.052ZM382.21 65.808h-15.186l.528-13.15h-8.751l-.71 13.15h-13.267V13.14c0-5.807 2.444-9.17 8.603-10.1L384.998.246l-2.788 65.562Zm-14.647-24.203 1.007-25.309-9.06.711-.709 24.587 8.762.011Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path
          fill="#fff"
          transform="translate(.055 .246)"
          d="M0 0h384.943v65.562H0z"
        />
      </clipPath>
    </defs>
  </svg>
);

export default Cryptomeda;
