import { createGlobalStyle } from "styled-components";
import { MediaQuery } from "../hooks/useDeviceType";
import {
  ALABASTER,
  EERIE_BLACK,
  GHOST_WHITE,
  PRIMARY_FONT,
  PRIMARY_SIZE,
} from "../utils/variables";

export const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
    -webkit-text-size-adjust:none;
  }

  html,body {
    position: relative;

  }

  html {
    font-size: 62.5%;
   

    @media ${MediaQuery.isTablet} {
      font-size: 56.5%;
    }

    @media ${MediaQuery.isMobile} {
        font-size: 50.5%;
    } 
  }

  body {
   font-size: ${PRIMARY_SIZE};
   font-family: ${PRIMARY_FONT};
   background-color: ${EERIE_BLACK};
   color: ${ALABASTER};

    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${GHOST_WHITE};
      border: 3px solid ${EERIE_BLACK};
      border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
      background-color: ${EERIE_BLACK};
    }
  }

  

  .bouncing {
    display: inline-block;
    animation: rubberband 800ms alternate ease-out;

    @keyframes rubberband {
      0% {
        transform: scaleX(1);
      }
      40% {
        transform: scaleX(1.12) scaleY(0.75);
      }
      55% {
        transform: scaleX(0.85) scaleY(1);
      }
      65% {
        transform: scaleX(1.09) scaleY(0.85);
      }
      75% {
        transform: scaleX(0.9) scaleY(1);
      }
      90% {
        transform: scaleX(1.05) scaleY(0.95);
      }
      100% {
        transform: scaleX(1) scaleY(1);
      }
    }
  }

  
`;
