export const commonUpAppearVariants = {
  closed: { opacity: 0, y: 120 },
  open: { opacity: 1, y: 0, transition: { duration: 0.5 } },
};

export const commonDownAppearVariants = {
  closed: { opacity: 0, y: -120 },
  open: { opacity: 1, y: 0, transition: { duration: 0.5 } },
};

export const commonOpacityAppearVariants = {
  closed: { opacity: 0 },
  open: { opacity: 1 },
};

export const parentVariants = {
  closed: { opacity: 0 },
  open: {
    opacity: 1,
    transition: { staggerChildren: 0.2, delay: 0.1 },
  },
};

export const sidebarVariants = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at 260px 40px)`,
    transition: {
      type: "spring",
      stiffness: 20,
      restDelta: 2,
    },
  }),
  closed: {
    clipPath: "circle(30px at 40px 40px)",
    transition: {
      delay: 0.2,
      type: "spring",
      stiffness: 400,
      damping: 40,
    },
  },
};

export const navigationVariants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};
