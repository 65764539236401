import styled from "styled-components";
import { MediaQuery } from "../../hooks/useDeviceType";
import {
  GAINSBORO,
  GHOST_WHITE,
  NORMAL_HEIGHT,
  NORMAL_WEIGHT,
  PRIMARY_FONT,
  SECONDARY_FONT,
  SECONDARY_SIZE,
  SLIM_WEIGHT,
  SMALL_TITLE_SIZE,
} from "../../utils/variables";

export const Container = styled.section`
  max-width: 110rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding: 10rem 12rem;

  @media ${MediaQuery.isTablet} {
    flex-direction: column;
  }

  @media ${MediaQuery.isMobile} {
    flex-direction: column;
    padding: 10rem 3rem;
  }
`;

export const Content = styled.div``;

export const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
  font-family: ${SECONDARY_FONT};
  margin-bottom: 4rem;

  &::after {
    content: "";
    width: 100%;
    height: 1px;
    display: block;
    background-color: ${GAINSBORO};
    opacity: 0.4;
    margin-left: 2rem;
  }
`;

export const Heading = styled.h1`
  margin-left: 1rem;
  font-family: ${PRIMARY_FONT};
  font-size: ${SMALL_TITLE_SIZE};
  white-space: nowrap;
`;

export const Text = styled.p`
  color: ${GAINSBORO};
  font-weight: ${SLIM_WEIGHT};
  font-size: ${SECONDARY_SIZE};
  line-height: ${NORMAL_HEIGHT};
  margin-bottom: 2rem;
`;

export const List = styled.ul`
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  @media ${MediaQuery.isMobile} {
    margin-top: 2rem;
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const Item = styled.li`
  display: flex;
  margin-bottom: 1rem;
  font-weight: ${SLIM_WEIGHT};
  font-size: ${SECONDARY_SIZE};

  &::before {
    content: "▹";
    display: block;
    margin-right: 1rem;
  }
`;

export const PhotoContainer = styled.figure`
  max-height: 40rem;
  min-width: 40rem;
  max-width: 40rem;
  border-radius: 2rem;
  margin-left: 4rem;
  position: relative;
  cursor: pointer;

  @media ${MediaQuery.isTablet} {
    margin-left: 0;
    margin-top: 6rem;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border: 2px solid ${GAINSBORO};
    top: 20px;
    left: 20px;
    z-index: -1;
    border-radius: 1rem;
    transition: 0.4s ease-in-out;
    -webkit-transition: 0.4s ease-in-out;
    -moz-transition: 0.4s ease-in-out;
    -ms-transition: 0.4s ease-in-out;
  }

  &:hover {
    img {
      filter: brightness(1);
      transition: 0.4s ease-in-out;
      -webkit-transition: 0.4s ease-in-out;
      -moz-transition: 0.4s ease-in-out;
      -ms-transition: 0.4s ease-in-out;
    }

    &::after {
      top: 10px;
      left: 10px;
      transition: 0.4s ease-in-out;
      -webkit-transition: 0.4s ease-in-out;
      -moz-transition: 0.4s ease-in-out;
      -ms-transition: 0.4s ease-in-out;
    }
  }

  @media ${MediaQuery.isMobile} {
    margin-left: 0;
    margin-top: 6rem;
    max-width: unset;
    max-height: unset;

    img {
      filter: brightness(1);
    }

    &::after {
      top: 10px;
      left: 10px;
    }
  }
`;

export const Photo = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 1rem;
  filter: brightness(0.7);
  transition: 0.4s ease-in-out;
  -webkit-transition: 0.4s ease-in-out;
  -moz-transition: 0.4s ease-in-out;
  -ms-transition: 0.4s ease-in-out;
`;

export const Link = styled.a`
  text-decoration: none;
  font-weight: ${NORMAL_WEIGHT};
  color: ${GHOST_WHITE};
  display: inline-block;
  position: relative;

  &:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: ${GHOST_WHITE};
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
`;
