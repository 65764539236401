import React, { useState, useEffect } from "react";
import { Container, Text } from "./Footer.Styled";

const Footer = () => {
  const [year, setYear] = useState("");

  function fetchCurrentYear() {
    const date = new Date();

    setYear(date.getFullYear());
  }

  useEffect(() => {
    fetchCurrentYear();
  }, []);

  return (
    <Container>
      <Text>© {year} Viet Hoang Nguyen | All rights reserved.</Text>
    </Container>
  );
};

export default Footer;
