import React from "react";
import Button from "../../components/button/Button";
import { Container, SpecialText, Heading, Text } from "./Contact.Styled";

const Contact = () => {
  return (
    <Container id="Contact">
      <SpecialText>05. What's Next?</SpecialText>
      <Heading>Get In Touch</Heading>
      <Text>
        Whether you have a question, offer or just want to say hi, I'll try my
        best to get back to you!
      </Text>
      <Button href="mailto:viethoang.ng7@gmail.com">Say Hello</Button>
    </Container>
  );
};

export default Contact;
