import styled from "styled-components";
import { MediaQuery } from "../../hooks/useDeviceType";
import {
  GAINSBORO,
  PRIMARY_FONT,
  SECONDARY_FONT,
  SMALL_TITLE_SIZE,
} from "../../utils/variables";

export const Container = styled.section`
  max-width: 110rem;
  margin: 0 auto;
  padding: 10rem 12rem 14rem;

  @media ${MediaQuery.isMobile} {
    padding: 10rem 3rem;
  }
`;

export const List = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);

  @media ${MediaQuery.isTablet} {
    grid-template-columns: repeat(4, 1fr);
  }

  @media ${MediaQuery.isMobile} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const SkillContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 2rem;
`;

export const SkillImage = styled.img`
  height: 8rem;
`;

export const Heading = styled.h1`
  margin-left: 1rem;
  font-family: ${PRIMARY_FONT};
  font-size: ${SMALL_TITLE_SIZE};
  white-space: nowrap;
`;

export const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
  font-family: ${SECONDARY_FONT};
  margin-bottom: 4rem;

  &::after {
    content: "";
    width: 100%;
    height: 1px;
    display: block;
    background-color: ${GAINSBORO};
    opacity: 0.4;
    margin-left: 2rem;
  }
`;

export const SkillName = styled.h1`
  font-size: 1.6rem;
  margin-top: 2rem;
  text-align: center;
`;
