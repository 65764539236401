import styled from "styled-components";
import { MediaQuery } from "../../hooks/useDeviceType";
import {
  GAINSBORO,
  GHOST_WHITE,
  NORMAL_HEIGHT,
  NORMAL_WEIGHT,
  PRIMARY_FONT,
  SECONDARY_FONT,
  SECONDARY_SIZE,
  SLIM_WEIGHT,
  TITLE_SIZE,
} from "../../utils/variables";

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 110rem;
  margin: 0 auto;
  padding: 0 12rem;
  height: 100vh;

  @media ${MediaQuery.isMobile} {
    padding: 0 3rem;
  }
`;

export const SpecialText = styled.p`
  font-family: ${SECONDARY_FONT};
  font-size: ${SECONDARY_SIZE};
  margin-bottom: 3rem;
`;

export const Title = styled.h1`
  font-family: ${PRIMARY_FONT};
  font-size: ${TITLE_SIZE};
  line-height: 8rem;
  color: ${GHOST_WHITE};

  @media ${MediaQuery.isMobile} {
    line-height: 4rem;
    font-size: 2.8rem;
  }
`;

export const Heading = styled.h1`
  font-family: ${PRIMARY_FONT};
  font-size: ${TITLE_SIZE};
  line-height: 8rem;

  @media ${MediaQuery.isMobile} {
    line-height: 4rem;
    font-size: 2.8rem;
  }
`;

export const Text = styled.p`
  margin-top: 2rem;
  max-width: 60%;
  color: ${GAINSBORO};
  font-weight: ${SLIM_WEIGHT};
  font-size: ${SECONDARY_SIZE};
  line-height: ${NORMAL_HEIGHT};

  @media ${MediaQuery.isTablet} {
    max-width: 80%;
  }

  @media ${MediaQuery.isMobile} {
    max-width: 100%;
  }
`;

export const Link = styled.a`
  text-decoration: none;
  font-weight: ${NORMAL_WEIGHT};
  color: ${GHOST_WHITE};
  display: inline-block;
  position: relative;

  &:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: ${GHOST_WHITE};
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
`;
