import * as React from "react";

export const TCGWorld = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
    }}
    width="120"
    height="50"
    viewBox="0 0 180 50"
    {...props}
  >
    <path
      d="M41.886 24.314a9.118 9.118 0 0 0-2.159-.458 10.66 10.66 0 0 0-1.248 1.874 6.242 6.242 0 0 1 2.075.144 7.373 7.373 0 0 1 1.332-1.56Zm10.656 34.909a7.369 7.369 0 0 1-1.332 1.56 9.118 9.118 0 0 0 2.159.458c.466-.555.883-1.182 1.249-1.874a6.274 6.274 0 0 1-2.076-.144Zm3.025-2.13a15.66 15.66 0 0 1-1.619-1.089 17.214 17.214 0 0 0 .442-2.042c.563.481 1.12.896 1.67 1.248-.135.655-.3 1.284-.493 1.883Zm-8.243-29.298c3.027 2.89 5.719 7.189 7.43 12.325 1.537 4.611 2.037 9.158 1.626 13.026a14.65 14.65 0 0 1-.99-.808 17.003 17.003 0 0 1-.765-.722c.152-3.275-.354-7.042-1.641-10.906-1.445-4.334-3.604-7.982-6.003-10.598a18.112 18.112 0 0 1 .343-2.317Zm-3.215-2.459c.544.317 1.084.681 1.619 1.089-.108.383-.206.784-.291 1.202-.056.275-.107.555-.152.84a15.071 15.071 0 0 0-1.67-1.248c.136-.655.3-1.284.494-1.883Zm-6.836-1.282c-.16.041-.318.088-.476.14-6.287 2.096-8.442 12.624-4.812 23.515S43.654 65.73 49.942 63.635a7.1 7.1 0 0 0 1.631-.788 12.72 12.72 0 0 1-2.48-.904c-2.205.605-4.996-.233-7.888-2.797-2.963-2.629-5.719-6.836-7.449-12.027-1.731-5.192-2.05-10.211-1.255-14.091.632-3.091 1.901-5.221 3.503-6.356.348-.931.771-1.811 1.269-2.618Z"
      style={{
        fill: "#3485ff",
      }}
      transform="translate(-30.222 -18.43)"
    />
    <path
      d="M61.834 54.63c1.547-4.516 1.419-10.816-.722-17.242-2.311-6.932-6.407-12.34-10.701-14.816-.493.267-.998.696-1.467 1.325.957.485 1.946 1.166 2.947 2.054 2.964 2.628 5.72 6.835 7.45 12.027 1.73 5.192 2.049 10.211 1.255 14.091a14.979 14.979 0 0 1-.806 2.703c.75.135 1.39.08 1.904-.091l.14-.051Zm-.906 2.093a6.51 6.51 0 0 1-2.035-.223 7.418 7.418 0 0 1-1.316 1.543 9.394 9.394 0 0 0 2.115.506c.459-.541.872-1.152 1.236-1.826ZM43.939 21.25a7.062 7.062 0 0 0-.785.212c-6.287 2.096-8.442 12.623-4.812 23.515 3.63 10.891 11.669 18.021 17.957 15.925a7.067 7.067 0 0 0 1.621-.781 12.916 12.916 0 0 1-2.415-.926c-2.213.635-5.027-.196-7.943-2.782-2.963-2.628-5.719-6.835-7.45-12.026-1.73-5.192-2.049-10.211-1.254-14.092.675-3.3 2.076-5.506 3.834-6.573.348-.88.763-1.71 1.247-2.472Zm1.166 1.725c.364-.673.776-1.285 1.236-1.826.699.08 1.407.251 2.115.507a7.385 7.385 0 0 0-1.316 1.542 6.543 6.543 0 0 0-2.035-.223Z"
      style={{
        fill: "#5930fe",
      }}
      transform="translate(-30.222 -18.43)"
    />
    <path
      d="M67.175 49.402c.794-3.881.475-8.9-1.255-14.091-1.73-5.192-4.486-9.399-7.45-12.027-3.006-2.666-5.903-3.467-8.146-2.719-2.244.748-4.082 3.127-4.887 7.063-.795 3.88-.476 8.899 1.255 14.091 1.73 5.192 4.486 9.399 7.449 12.027 3.006 2.666 5.904 3.466 8.147 2.718 2.243-.747 4.081-3.126 4.887-7.062Zm-4.297 8.833c6.288-2.096 8.442-12.623 4.813-23.515-3.63-10.891-11.67-18.021-17.958-15.925-6.287 2.095-8.442 12.623-4.812 23.514s11.67 18.022 17.957 15.926Z"
      style={{
        fill: "#32e322",
      }}
      transform="translate(-30.222 -18.43)"
    />
    <path
      d="M88.536 47.94V33.286h-4.977v-2.528h13.1v2.528h-5.001V47.94h-3.122ZM106.085 48.235c-1.724 0-3.204-.368-4.44-1.105-1.236-.753-2.187-1.792-2.854-3.117-.666-1.342-1-2.888-1-4.639 0-1.751.334-3.298 1-4.64.667-1.341 1.618-2.389 2.854-3.141 1.236-.753 2.716-1.13 4.44-1.13 2.049 0 3.724.516 5.025 1.547 1.317 1.014 2.139 2.446 2.464 4.295h-3.44c-.211-.932-.666-1.661-1.366-2.184-.683-.54-1.593-.81-2.732-.81-1.577 0-2.813.54-3.708 1.62-.894 1.08-1.341 2.561-1.341 4.443 0 1.882.447 3.362 1.341 4.443.895 1.063 2.131 1.595 3.708 1.595 1.139 0 2.049-.245 2.732-.736.7-.508 1.155-1.203 1.366-2.087h3.44c-.325 1.767-1.147 3.15-2.464 4.148-1.301.999-2.976 1.498-5.025 1.498ZM123.802 48.235c-1.61 0-3.017-.368-4.22-1.105-1.203-.736-2.138-1.759-2.805-3.068-.667-1.326-1-2.864-1-4.615 0-1.767.341-3.322 1.024-4.664.699-1.358 1.683-2.413 2.952-3.166 1.285-.769 2.805-1.154 4.561-1.154 2.001 0 3.659.483 4.977 1.449 1.317.965 2.171 2.299 2.561 4.001h-3.488c-.26-.802-.732-1.424-1.415-1.866-.683-.458-1.561-.687-2.635-.687-1.707 0-3.024.548-3.951 1.644-.927 1.08-1.391 2.57-1.391 4.468 0 1.898.456 3.371 1.366 4.418.927 1.031 2.179 1.547 3.757 1.547 1.545 0 2.708-.418 3.488-1.252.797-.851 1.277-1.964 1.439-3.339h-4.317V38.49h7.464v9.45h-2.878l-.244-2.258a5.737 5.737 0 0 1-2.074 1.89c-.829.442-1.886.663-3.171.663Z"
      style={{
        fillRule: "nonzero",
      }}
      transform="translate(-30.222 -18.43)"
    />
    <path
      d="m141.31 47.943-4.382-16.994h3.245l2.984 13.523 3.505-13.523h3.34l3.41 13.523 2.984-13.523h3.269l-4.5 16.994h-3.6l-3.292-12.6-3.387 12.6h-3.576ZM166.179 48.235c-1.137 0-2.164-.267-3.079-.801a5.957 5.957 0 0 1-2.156-2.21c-.521-.955-.781-2.055-.781-3.301 0-1.247.268-2.339.805-3.278.537-.955 1.255-1.699 2.155-2.234.916-.534 1.942-.801 3.079-.801 1.121 0 2.132.267 3.032.801a5.691 5.691 0 0 1 2.155 2.234c.537.939.805 2.031.805 3.278 0 1.246-.268 2.346-.805 3.301a5.729 5.729 0 0 1-2.155 2.21c-.916.534-1.934.801-3.055.801Zm0-2.695c.789 0 1.476-.3 2.06-.898.584-.615.876-1.522.876-2.719 0-1.198-.292-2.096-.876-2.695-.584-.615-1.263-.923-2.037-.923-.805 0-1.5.308-2.084.923-.568.599-.853 1.497-.853 2.695 0 1.197.285 2.104.853 2.719.584.598 1.271.898 2.061.898ZM174.905 47.943V35.902h2.7l.284 2.258a4.923 4.923 0 0 1 1.729-1.846c.742-.469 1.61-.704 2.605-.704v3.278h-.853c-.663 0-1.255.105-1.776.315-.521.211-.931.575-1.231 1.093-.285.518-.427 1.238-.427 2.161v5.486h-3.031ZM184.295 30.463h3.031v17.48h-3.031zM195.824 48.235c-1.105 0-2.092-.275-2.961-.826-.868-.55-1.555-1.303-2.06-2.257-.505-.955-.758-2.04-.758-3.254s.253-2.29.758-3.229c.505-.955 1.192-1.699 2.06-2.233.869-.551 1.856-.826 2.961-.826.884 0 1.658.17 2.321.51.663.34 1.2.818 1.61 1.433v-7.09h3.032v17.48h-2.7l-.332-1.723c-.379.534-.884 1.003-1.515 1.408-.616.404-1.421.607-2.416.607Zm.639-2.719c.979 0 1.777-.332 2.392-.996.632-.679.948-1.545.948-2.597s-.316-1.91-.948-2.574c-.615-.68-1.413-1.02-2.392-1.02-.963 0-1.76.332-2.392.996-.631.663-.947 1.521-.947 2.573s.316 1.918.947 2.598c.632.68 1.429 1.02 2.392 1.02Z"
      style={{
        fill: "#32e322",
        fillRule: "nonzero",
      }}
      transform="translate(-30.222 -18.43)"
    />
  </svg>
);

export default TCGWorld;
