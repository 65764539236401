import styled from "styled-components";
import {
  EERIE_BLACK,
  GHOST_WHITE,
  SECONDARY_FONT,
  SMALL_SECONDARY_SIZE,
} from "../../utils/variables";

export const Container = styled.a`
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  border-color: ${GHOST_WHITE};
  padding: 1rem 2rem;
  color: ${GHOST_WHITE};
  cursor: pointer;
  background: linear-gradient(to left, transparent 50%, ${GHOST_WHITE} 50%)
    right;
  font-family: ${SECONDARY_FONT};
  font-size: ${SMALL_SECONDARY_SIZE};
  background-position: right;
  background-size: 200% 100%;
  transition: 0.5s ease;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: none;

  &:hover {
    color: ${EERIE_BLACK};
    background-position: left;
  }

  &:focus {
    outline: none;
  }
  &:active {
    transform: scale(0.98);
  }
`;
